String.prototype.endWith = function(endStr){
      var d = this.length - endStr.length;
      return (d >= 0&&this.lastIndexOf(endStr) == d)
}

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function millsToTime (mills) {
  if (!mills) {
    return ''
  }
  const s = mills / 1000
  if (s < 60) {
    return s.toFixed(0) + ' 秒'
  }
  const m = s / 60
  if (m < 60) {
    return m.toFixed(0) + ' 分钟'
  }
  const h = m / 60
  if (h < 24) {
    return h.toFixed(0) + ' 小时'
  }
  const d = h / 24
  if (d < 30) {
    return d.toFixed(0) + ' 天'
  }
  const month = d / 30
  if (month < 12) {
    return month.toFixed(0) + ' 个月'
  }
  const year = month / 12
  return year.toFixed(0) + ' 年'
};

export function numberToChinese(num){
	const numChar=["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"]
	const unitChar=["", "拾", "佰", "仟"]
	const unitSection=["", "万", "亿", "万亿", "亿亿"]
	var returnStr='' //返回值
	var unitPos=0
	var unitSec=-1
	var strIns=''
	var zero=true //最后一个值是不是0
	while(num>0){
		var n=num%10 //找到最后一个值
		if(n===0){
			if(!zero){
				zero=true
				returnStr=numChar[n]+returnStr
			}
			if(unitPos%4==0){ //解决10010(万位是0时需要添加万)
				unitSec++
				unitPos=0
				returnStr=unitSection[unitSec]+returnStr
			}
		}else{
			zero=false;
			strIns = numChar[n];
			if(unitPos%4==0){
				unitSec++
				unitPos=0
				strIns+=unitSection[unitSec]
			}else{
				strIns+=unitChar[unitPos];
			}
			returnStr=strIns+returnStr
		}
		unitPos++
		num = Math.floor(num/10); //重新给num赋值，以便下次循环找到前一个值
	}
	return returnStr
}

// export function formatMoneyOfRound(data) {
//   var f_x = parseFloat(data);
//   if (isNaN(f_x)) {
//     return 0;
//   }
//   f_x = Math.round(data * 100) / 100;
//   return f_x;
// };
export function formatMoneyOfRound(data) {
  var f_x = parseFloat(data);
  if (isNaN(f_x)) {
    return 0;
  }
  f_x = data * 100 / 100;
  return f_x;
};



// export function formatMoney(num) {
//   var f_x = parseFloat(num);
//     if (isNaN(f_x)) {
//       return 0;
//     }
//     f_x = Math.floor(num * 100) / 100;
//     var s_x = f_x.toFixed(2);
//     let money =
//       s_x.indexOf('.') != -1
//         ? s_x.replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
//           return $1 + ',';
//         })
//         : s_x.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
//     return money;
// }
export function formatMoney(num) {
  var f_x = parseFloat(num);
    if (isNaN(f_x)) {
      return 0;
    }
    f_x =(num * 100) / 100;
    var s_x = f_x.toFixed(2);
    let money =
      s_x.indexOf('.') != -1
        ? s_x.replace(/(\d)(?=(\d{3})+\.)/g,function($0,$1) {
          return $1 + ',';
        })
        : s_x.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    return money;
}
//如果是uinx时间戳记得乘于1000，时间戳转时间
export function setTime(str){
  var n = parseInt(str);
  var D = new Date(n);
  var year = D.getFullYear();//四位数年份

  var month = D.getMonth()+1;//月份(0-11),0为一月份
  month = month<10?('0'+month):month;

  var day = D.getDate();//月的某一天(1-31)
  day = day<10?('0'+day):day;

  var hours = D.getHours();//小时(0-23)
  hours = hours<10?('0'+hours):hours;

  var minutes = D.getMinutes();//分钟(0-59)
  minutes = minutes<10?('0'+minutes):minutes;

  var seconds = D.getSeconds();//秒(0-59)
  seconds = seconds<10?('0'+seconds):seconds;
  // var week = D.getDay();//周几(0-6),0为周日
  // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

  var now_time = year+'-'+month+'-'+day+' '+hours+':'+minutes+':'+seconds;
  return now_time;
}