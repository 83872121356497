<!--
 * @Author: AKJERRT 1617572569@qq.com
 * @Date: 2022-07-06 10:52:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-13 17:34:12
 * @FilePath: \HuiLiang\affair\src\components\initModal\initModal.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <a-modal :visible="status" :maskClosable="false" :closable="false">
      <a-badge :count="this.$store.state.common.modalData" :offset="[10, 0]">
        <a-icon class="notification" type="notification" />
      </a-badge>
      <p class="article">您有未对账的账单,请尽快提醒或代经销商完成对账！！！</p>
      <template slot="footer">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      confirmLoading: false,
    }
  },
  computed: {
    status: {
      get() {
        return this.$store.state.common.loginModal
      },
      set(value) {
        this.$store.commit('common/SET_LOFIN_MODAL',value)
      },
    },
  },
  methods: {
    handleOk(e) {
      this.confirmLoading = true
      setTimeout(() => {
        this.$store.commit('common/SET_LOFIN_MODAL_ClOSE')
        this.confirmLoading = false
        this.$router.push({
          name: 'accountStatementList',
        })
      }, 2000)
    },
  },
}
</script>
<style lang="less" scoped>
.notification {
  height: 1rem;
  width: 16px;
}
.article {
  margin-top: 1rem;
}
</style>