<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-21 09:54:19
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-25 09:19:55
-->
<template>
  <div class="logo">
    <router-link :to="{name:'dashboard'}">
      <img src="~@/assets/logo.png" class="logo-img" alt="logo">
      <!-- <h1 v-if="showTitle">{{ title }}</h1> -->
    </router-link>
  </div>
</template>

<script>
// import LogoSvg from '@/assets/logo.svg?inline'

export default {
  name: 'Logo',
  // components: {
  //   LogoSvg
  // },
  props: {
    title: {
      type: String,
      default: '指房向',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>
<style lang="scss">
  .logo {

    text-align: center;

    .logo-img {
      width: 90% !important;
      height: auto !important;
    }
  }
</style>
