<template>
  <div class="user-wrapper">
    <div class="content-box">
      <!-- <a href="http://doc.rycloud.zmrit.com/#/quick-start" target="_blank">
        <span class="action">
          <a-icon type="question-circle-o"></a-icon>
        </span>
      </a> -->
      <!-- <notice-icon class="action"/> -->
      <!-- <lang-select /> -->
      <MiniSettingDrawer></MiniSettingDrawer>
      <span v-if="userInfo.firmName != null" class="dept-span">
        {{userInfo.firmName}}
      </span>
      <span v-if="userInfo.firmName != null">
        ｜
      </span>
      您好：
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" :src="avatar"/>
          <span>{{ nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <!-- <a-menu-item key="0">
            <router-link :to="{ name: 'center' }">
              <a-icon type="user"/>
              <span>个人中心</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="1">
            <router-link :to="{ name: 'settings' }">
              <a-icon type="setting"/>
              <span>账户设置</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="2" disabled>
            <a-icon type="setting"/>
            <span>测试</span>
          </a-menu-item>
          <a-menu-divider/> -->
          <a-menu-item class="cz-item" key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>

    </div>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import LangSelect from '@/components/tools/LangSelect'
import { mapActions, mapGetters } from 'vuex'
import MiniSettingDrawer from '@/components/MiniSettingDrawer'
import { ACCESS_CODE } from '@/store/mutation-types'

export default {
  name: 'UserMenu',
  components: {
    LangSelect,
    NoticeIcon,
    MiniSettingDrawer
  },
  computed: {
    ...mapGetters(['nickname', 'avatar','userInfo'])
  },
  methods: {
    ...mapActions(['Logout']),
    handleLogout () {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗?',
        onOk: () => {
          return this.Logout({}).then(() => {
            setTimeout(() => {
              if(process.env.NODE_ENV === "myenv" || Vue.ls.get(ACCESS_CODE) == 'TC') {
                window.location.reload()
              }else{
                let address = 1
                window.location.href=`https://cloud.5uplus.com/login?address=${address}`
              }
            }, 16)
          }).catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    }
  }
}
</script>
<style>
.dept-span{
  font-size: 17px;
  font-weight: bold;
}

.cz-item{
  width: 100% !important;
}

</style>
