/*
 * @Description: AKJERRT
 * @Date: 2022-04-06 09:48:45
 * @LastEditTime: 2022-07-13 09:10:01
 * @FilePath: \HuiLiang\affair\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import i18n from './modules/i18n'
import permission from './modules/permission'
import getters from './getters'
import common from "./modules/common"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    i18n,
    permission,
    common
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
