import Vue from 'vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { notification } from 'ant-design-vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['login', 'register', 'registerResult','realtorList','wap','info'] // no redirect whitelist
function searchObj() {
  let pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for ( i in pairs ) {
    if ( pairs[i] === "" ) continue;

    pair = pairs[i].split("=");
    obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
  }

  return obj;
};

router.beforeEach((to, from, next) => {
  // if (to.name != 'login' && to.name != 'accountStatementList' && to.name != null) {
  //   store.dispatch('common/getInitBill')
  // }
  NProgress.start() // start progress bar
  if (Vue.ls.get(ACCESS_TOKEN)) {
    let loginInfo = searchObj()
    loginInfo.token && store.dispatch('Login', loginInfo)
    if (to.path === '/login') {
      next({ path: '/dashboard/analysis' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo')
          .then(() => {
            // 调用 vuex 的 从后端获取用户的路由菜单，动态添加可访问路由表
            store.dispatch('GenerateRoutes').then(() => {
              // 把已获取到的路由菜单加入到路由表中
              router.addRoutes(store.getters.addRouters)
              console.log(store.getters.addRouters)
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(err => {
            console.log(err);
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试'
            })
            store.dispatch('Logout').then(() => {
              next({ path: '/login', query: { redirect: to.fullPath } })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if(process.env.NODE_ENV === "myenv") {
        next({ path: '/login', query: { redirect: to.fullPath } })
      } else {
        let loginInfo = searchObj()
        if(!loginInfo.code){
          next({ path: '/login', query: { redirect: to.fullPath } })
        }else{
          store.dispatch('Login', loginInfo).then(res => {
            next({ path: '/dashboard/analysis', query: { redirect: to.fullPath } })
          })
        }

      }
      // next({ path: '/login', query: { redirect: to.fullPath } })
      // let loginInfo = searchObj(window.location.search)
      // store.dispatch('Login',loginInfo).then(res=>{
      //   next({path:'/dashboard/analysis',query: { redirect: to.fullPath }})
      // })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
