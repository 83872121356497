<template>
  <div>
     <a-range-picker
      :show-time="{ format: 'HH:mm:ss' }"
      format="YYYY-MM-DD HH:mm:ss"
      :placeholder="['开始时间', '结束时间']"
      @change="onChange"
      :defaultValue="getValve"
      @ok="onOk"
      :allowClear="false"
      style="width:80%;"
    />
  </div>
</template>
<script>
import moment from 'moment'
export default {
    data(){
      return{
          list:[],
          createValue:[]
      }
    },
    props: {
       startTime: {
         type: String
       },
       endTime: {
         type: String
       },
    },
    computed:{
      getValve(){
         if(this.startTime && this.endTime){
           let timeList =[moment(this.startTime, 'YYYY-MM-DD HH:mm:ss'), moment(this.endTime, 'YYYY-MM-DD HH:mm:ss')]
           return timeList
         }else{
           return []
         }
      },
    },
    watch:{
      list(newVal){
          if (newVal) {
           this.list = newVal
         }else{
           this.list = []
         }
      },
    },
  methods: {
    
    onChange(value, dateString) {
      this.createValue = value
      this.list = dateString
    },
    onOk(value) {
        let obj = {
            startTime:this.list[0],
            endTime:this.list[1],
        }
      this.$emit('timeSelect',obj)
    },
  },
};
</script>
