import Vue from 'vue'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { ACCESS_CODE } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
  state: {
    userId: '',
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    buttons: [], // 按钮权限
    info: {},
    firm:{},
    codeId:''
  },

  mutations: {
    SET_CODE: (state, codeId) => {
      state.codeId = codeId
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_FIRM:(state,firm) =>{
      state.firm = firm
    }
  },

  actions: {
        // 登录
        Login1 ({ commit }, userInfo) {
          return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
              if (response.code === 200) {
                const result = response.body
                Vue.ls.set(ACCESS_TOKEN, result.token, 12 * 60 * 60 * 1000)
                commit('SET_TOKEN', result.token)
                Vue.ls.set(ACCESS_CODE,'TC')
                resolve(response)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        if(process.env.NODE_ENV === "myenv"){
            login(userInfo).then(response => {
          if (response.code === 200) {
            const result = response.body
            Vue.ls.set(ACCESS_TOKEN, result.token, 12 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
        }else{
          Vue.ls.set(ACCESS_TOKEN, userInfo.token, 12 * 60 * 60 * 1000)
          commit('SET_TOKEN', userInfo.token)
          Vue.ls.set(ACCESS_CODE,'AB')
          resolve(true)
        }
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.body
          if (result.roleIds) {
            commit('SET_ROLES', result.roleIds)
            commit('SET_BUTTONS', result.buttons)
            commit('SET_USERID', result.id)
            commit('SET_FIRM', result.firm)
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          // console.log(result)
          commit('SET_NAME', { name: result.name, welcome: welcome() })
          let head = 'https://oss.vclus.comboy.png';
          if(result.sex == 2){
            head = 'https://oss.vclus.com/girl.png'
          }
          commit('SET_AVATAR', result.headPortrait || head)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_INFO', {})
          // commit('SET_CODE', '')
          Vue.ls.remove(ACCESS_TOKEN)
        })
      })
    }

  }
}

export default user
