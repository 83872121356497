/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-04-06 09:51:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-19 14:37:49
 */
const api = {
  Login: '/api/base/firm/auth/login',
  Logout: '/api/base/firm/auth/logout',
  ForgePassword: '/api/auth/forge-password',
  Register: '/api/auth/register',
  twoStepCode: '/api/auth/2step-code',
  SendSms: '/api/account/sms',
  SendSmsErr: '/api/account/sms_err',
  // get my info
  UserInfo: '/api/base/system/firm/manager/getUserInfo',
  // system
  user: '/api/system/user',
  role: '/api/system/role',
  permission: '/api/system/menu',
  dept: '/api/system/dept',
  dictType: '/system/dict/type',
  dictData: '/system/dict/data',
  dist: '/system/districts',
  config: '/system/config',
  oss: '/system/oss',
  donate: '/system/donate',
  models: '/api/workflow/models',
  prof: '/api/workflow/prof',
  process: '/api/workflow/process',
  processIns: '/api/workflow/process/ins',
  business: '/api/workflow/business',
  leave: '/api/workflow/leave',
  purchase: '/api/workflow/purchase',
  node: '/api/workflow/node',
  task: '/api/workflow/task'
  // models: '/act/models',
  // prof: '/act/prof',
  // process: '/act/process',
  // processIns: '/act/process/ins',
  // business: 'act/business',
  // leave: 'act/leave',
  // purchase: 'act/purchase',
  // node: 'act/node',
  // task: 'act/task'
}
export default api
