<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-24 09:51:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-07 14:45:48
-->
<template>
  <div class="">
    <a-modal :maskClosable="false" title="操作" style="top: 8px" width="85%" v-model="visible" :footer="null">
      <a-tabs type="card" @change="callback">
        <a-tab-pane key="1" tab="订单信息">
          <a-form-model ref="form" :model="rowData" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
            <div class="title">支付信息</div>
            <a-row :gutter="4">
              <a-col :md="8">
                <a-form-model-item class="space_between" label="支付总金额" style="font-size: 20px;color: red;">{{ rowData.actualPrice | formatMoney }}</a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="余额支付">{{ rowData.actualPriceBalance | formatMoney  }}</a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="额度支付">{{ rowData.actualPriceLimit | formatMoney  }}</a-form-model-item>
              </a-col>
            </a-row>
            <div class="title">订单信息</div>
            <a-row :gutter="4">
              <a-col :md="8">
                <a-form-model-item class="space_between" label="订单编号">{{ rowData.orderCode }}</a-form-model-item>
              </a-col>
              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="期望到货时间">-->
              <!--                  {{ rowData.expectTime || '-&#45;&#45;' }}-->
              <!--                </a-form-model-item>-->
              <!--              </a-col>-->
              <a-col :md="8">
                <a-form-model-item class="space_between" label="收货人">{{ rowData.consignee }}</a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="4">
              <a-col :md="8">
                <a-form-model-item class="space_between" label="联系电话">{{ rowData.phone }}</a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="收货地址">
                  {{ rowData.provinceName }}{{ rowData.cityName }}{{ rowData.areaName }}{{ rowData.address }}
                </a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="订单备注">
                  {{ !rowData.orderRemark ? '暂无' : rowData.orderRemark }}
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="4" v-if="handle == 'check'">
              <a-col :md="8">
                <a-form-model-item class="space_between" label="发货人">
                  {{ rowData.createUser }}
                </a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="发货时间">
                  {{ rowData.deliveryTime  }}
                </a-form-model-item>
              </a-col>
              <a-col :md="8"></a-col>
            </a-row>

            <div v-if="handle != 'check'">
              <div class="title">快递信息</div>
              <a-row :gutter="4">
                <a-col :md="12">
                  <a-form-model-item
                    label="快递公司类型"
                    :rules="[{ required: true, message: '快递公司类型', trigger: 'blur' }]"
                  >
                    <a-select placeholder="请选择" @change="onChange" v-model="rowData.deliveryType">
                      <a-select-option :value="item.id" v-for="(item, index) in LogisticsType" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :md="12">
                  <a-form-model-item
                    label="请选择物流公司"
                    :rules="[{ required: true, message: '请选择物流公司', trigger: 'blur' }]"
                  >
                    <a-select placeholder="请选择" v-model="wuliu" label-in-value @change="onBrandChange">
                      <a-select-option :value="item.code" v-for="(item, index) in deliverys" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="4">
                <a-col :md="12">
                  <a-form-model-item
                    label="快递单号"
                    :rules="[{ required: true, message: '请填写快递单号', trigger: 'blur' }]"
                  >
                    <a-input v-model.trim="rowData.deliveryNum" placeholder="请填写快递单号" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :md="12">
                  <a-form-model-item label="物流信息" prop="logisticsRemark" v-show="showLogisticsRemark">
                    <a-input v-model.trim="rowData.logisticsRemark" placeholder="物流信息" :max-length="100"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :md="12">
                  <a-form-model-item label="附件：" prop="(logisticsPicture" v-show="showLogisticsRemark">
                    <QiniuUpload type="image" :value.sync="rowData.logisticsPicture"></QiniuUpload>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>

            <div class="title">商品信息</div>
            <a-table :data-source="tableData" :rowKey="(record) => record.productId" :pagination="false" bordered>
              <a-table-column title="订单编号" data-index="orderCode" align="center"></a-table-column>
              <a-table-column title="商品名称" data-index="name" align="center"></a-table-column>
              <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
              <a-table-column title="规格" data-index="specStr" align="center"></a-table-column>
              <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
              <!-- <a-table-column title="建议零售价" data-index="sellPrice" align="center">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column> -->
              <!-- <a-table-column title="数量" data-index="" align="center" :width="120"></a-table-column> -->
              <a-table-column title="型号" data-index="barCode" align="center" :width="120"></a-table-column>
              <a-table-column title="商家编码" data-index="sellerCode" align="center" :width="120"></a-table-column>
              <a-table-column title="物料编码" data-index="goodsCode" align="center" :width="120"></a-table-column>
              <a-table-column title="发货数量" data-index="shipNum" align="center" :width="120"></a-table-column>
              <!-- <a-table-column title="单位" data-index="proUnit" align="center" :width="150"></a-table-column> -->
            </a-table>
            <div style="text-align: right; margin-top: 14px" v-if="handle != 'check'">
              <a-button type="default" @click="toCancel">取消</a-button>
              <a-button type="primary" @click="toSubmit">确认</a-button>
            </div>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="2" tab="物流信息" v-if="handle == 'check' && rowData.deliveryStatus != 0">
          <div style="margin-bottom: 30px; display: flex; align-items: center">
            <div class="ship_logo">
              <img :src="deliveryinfo.ShipperLogo" alt="" style="width: 130px; height: 100px" />
            </div>
            <div>
              <div style="margin-bottom: 5px">
                <span>承运公司：</span><span>{{ !deliveryinfo.ShipperName ? '暂无' : deliveryinfo.ShipperName }}</span>
              </div>
              <div>
                <span>快递单号： </span
                ><span>{{ !deliveryinfo.LogisticCode ? '暂无' : deliveryinfo.LogisticCode }}</span>
              </div>
            </div>
          </div>
          <div style="height: 1px; background: #e9e9e9; margin: 20px 0 30px 0"></div>

          <div style="width: 100%; margin-left: 20%">
            <a-timeline>
              <a-timeline-item v-for="(item, index) in dataList" :key="index">
                <div v-if="item.action">物流状态: {{ item.action }}</div>
                <div v-if="item.logisticsPicture">
                  <img :src="item.logisticsPicture" style="width: 100px; height: 100px" /><br />
                </div>
                {{ item.acceptStation }} <br />
                <div v-if="item.location">当前所在城市: {{ item.location }}<br /></div>
                {{ item.acceptTime }}
              </a-timeline-item>
            </a-timeline>
          </div>
        </a-tab-pane>
      </a-tabs>
      <!-- <div class="footer_btns">
        <a-button type="default">取消</a-button>
        <a-button type="primary" >确定</a-button>
      </div> -->
    </a-modal>
    <CheckLogistics ref="CheckLogistics"></CheckLogistics>
  </div>
</template>

<script>
import {selectByCodeOrderShipInfo, selectByIdOrderShipInfo} from '../api/OrderShipInfoApi'
import CheckLogistics from './CheckLogistics.vue'
import {setTime} from '@/utils/util'
export default {
  name: '',
  components: {
    CheckLogistics,
  },

  data() {
    return {
      showLogisticsRemark: true,
      handle: '',
      isLoading: false,
      visible: false,
      rowData: {},
      deliverys: [],
      tableData: [],
      LogisticsType: [
        {
          id: 1,
          name: '自主查询',
        },
        {
          id: 2,
          name: '手动查询',
        },
      ],
      dataList: [],
      deliveryinfo: {},
      warehouseList: [],
      wuliu: {},
      createTime:''
    }
  },

  computed: {},

  created() {},

  methods: {
    setRowData(row, handle) {
      this.visible = true
      this.handle = handle
      this.wuliu = {}
      this.rowData = {}

      this.getList(row.supplierId)
      this.rowData.id = row.id
      if (handle === 'ship' || handle === 'check') {
        selectByIdOrderShipInfo(row.id).then((res) => {
          this.rowData = res.body
          this.tableData = this.rowData.orderShipDetails
          this.rowData.deliveryType = 1
          this.showLogisticsRemark = false
          this.createTime =setTime(this.rowData.createTime)
          this.getLogisticsList(1)
          if (this.rowData.deliveryType != 0) {
            if (handle === 'check') {
              let obj = {
                customerName: row.phone.substr(row.phone.length - 4),
                logisticCode: row.deliveryNum,
                shipperCode: row.deliveryCode,
              }
              if(this.rowData.deliveryStatus != 0){
                this.axios.post(`/api/order/logistics/logisticsInfo/queryDeiveryExpressList`, obj).then((res) => {
                  this.dataList = res.body.Traces
                  this.deliveryinfo = res.body
                })
              }
            }
          }
        })
      }
    },
    setRowDataByCode(row, handle) {
      this.visible = true
      this.handle = handle
      this.wuliu = {}
      this.rowData = {}
      this.getList(row.supplierId)
      this.rowData.id = row.id
      if (handle === 'ship' || handle === 'check') {
        selectByCodeOrderShipInfo(row.orderCode).then((res) => {
          this.rowData = res.body
          this.tableData = this.rowData.orderShipDetails
          this.rowData.deliveryType = 1
          this.showLogisticsRemark = false
          this.getLogisticsList(1)
          if (this.rowData.deliveryType != 0) {
            if (handle === 'check') {
              let obj = {
                customerName: this.rowData.phone.substr(this.rowData.phone.length - 4),
                logisticCode: this.rowData.deliveryNum,
                shipperCode: this.rowData.deliveryCode,
              }
              if(this.rowData.deliveryStatus != 0){
                this.axios.post(`/api/order/logistics/logisticsInfo/queryDeiveryExpressList`, obj).then((res) => {
                  this.dataList = res.body.Traces
                  this.deliveryinfo = res.body
                })
              }
            }
          }
        })
      }
    },

    getList(supplierId) {
      let text = supplierId == 11 ? '菱感仓库' : '舜天仓库'
      this.axios.get('/api/base/system/dictionary/selectByCode/' + text).then((res) => {
        // 获取字典仓库
        this.warehouseList = res.body
      })
    },

    // 选中仓库
    onWarehouseChange(val) {
      console.log(val)
    },

    toCancel() {
      this.visible = false
    },

    callback(key) {
      // if (key == 2) {
      //   this.$refs.CheckLogistics.getData(this.rowData.shipCode)
      // }
    },

    onBrandChange(val) {
      this.rowData.deliveryCode = val.key
      this.rowData.deliveryName = val.label
    },

    onChange(val) {
      if (val === 1) {
        this.showLogisticsRemark = false
      } else {
        this.showLogisticsRemark = true
      }
      this.getLogisticsList(val)
    },

    getLogisticsList(val) {
      this.wuliu =  {}
      this.deliverys = [];
      this.axios.get('/api/order/logistics/logisticsCompany/list', { params: { queryType: val } }).then((res) => {
        this.deliverys = res.body.records
      })
    },

    // 提交
    toSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true
          // this.rowData.orderInfoDetailVOList = this.tableData.map((e) => {
          //   return {
          //     demandGoodsNum: e.shipNum,
          //     proName: e.name,
          //     proSku: e.id,
          //     purchaseNum: e.realShipNum,
          //     shipNum: e.shipNum,
          //     ...e,

          //   }
          // })
          let obj = {
            acceptStation: this.rowData.logisticsRemark,
            deliveryType: this.rowData.deliveryType,
            id: this.rowData.id,
            logisticCode: this.rowData.deliveryNum,
            logisticsPicture: this.rowData.logisticsPicture,
            shipperCode: this.rowData.deliveryCode,
            shipperName: this.rowData.deliveryName
          }
          this.axios
            .post(`/api/order/system/orderShipInfo/updateOther`, obj)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.visible = false
                this.$emit('reload')
              }
            })
            .catch((err) => {
              this.isLoading = false
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
.space_between {
  margin-bottom: 0 !important;
}
</style>