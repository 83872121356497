<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-04 15:07:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-15 15:16:36
-->
<template>
  <div>
    <!-- <a-date-picker v-model="startTime2" :default-value="startTime2" placeholder="开始时间" @change="changeStartTime" style="width: 50%;"
                   :get-canlender-container="trigger=>trigger.parentNode" :disabled-date="disabledStartDate"></a-date-picker>
    <a-date-picker v-model="endTime2" :default-value="endTime2" placeholder="结束时间" @change="changeEndTime" style="width: 50%;"
                   :get-canlender-container="trigger=>trigger.parentNode" :disabled-date="disabledEndDate"></a-date-picker> -->
    <a-range-picker v-model="getDateTime" @change="onChangeShipDate" />
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      startTime2: this.isNull ? '' : moment().subtract(3, 'months'),
      endTime2: this.isNull ? '' : moment(),
      dateTime: [],
    }
  },
  props: {
    startTime: {
      type: [String, Object],
      default: '',
    },
    endTime: {
      type: [String, Object],
      default: '',
    },
    isNull: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    // startTime(newVal, oldVal) {
    //   this.startTime2 = newVal ? moment(newVal) : undefined
    // },
    startTime: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // this.startTime2 = newVal ? moment(newVal) : moment().format('YYYY-MM-DD MM:HH:SS')
          this.startTime2 = newVal ? moment(newVal) : undefined
          console.log(this.startTime2)
        }
      },
    },
    endTime: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // this.endTime2 = newVal ? moment(newVal) : moment().format('YYYY-MM-DD MM:HH:SS')
          this.endTime2 = newVal ? moment(newVal) : undefined
          console.log(this.endTime2)
        }
      },
    },
    // endTime(newVal, oldVal) {
    //   this.endTime2 = newVal ? moment(newVal) : undefined
    // },
  },

  computed: {
    getDateTime: {
      get() {
        if (this.startTime2 && this.endTime2) {
          return (this.dateTime = [this.startTime2, this.endTime2])
        }
        return []
      },
      set(value) {
        this.dateTime = value
      },
    },
    // getDateTime() {
    //   return this.dateTime = [this.startTime2, this.endTime2]
    // }
  },

  methods: {
    onChangeShipDate(date, dateString) {
      this.startTime2 = dateString[0]
      this.endTime2 = dateString[1]
      this.changeStartTime()
      this.changeEndTime()
    },
    changeStartTime() {
      this.$emit('update:startTime', this.startTime2)
    },
    changeEndTime() {
      this.$emit('update:endTime', this.endTime2)
    },
    disabledStartDate(startValue) {
      const endValue = this.endTime2
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startTime2
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
  },
  created() {
    this.$emit('update:startTime', this.startTime2)
    this.$emit('update:endTime', this.endTime2)
  },
}
</script>

<style lang="less" scoped>
</style>
